"use client";

import Link from "next/link";
import { useEffect } from "react";

import { EmptyPlaceholder } from "~/components/empty-placeholder";
import { Translation } from "~/components/translations/component";
import { Button, buttonVariants } from "~/components/ui/button";
import { logger } from "~/lib/logger";
import { cn } from "~/lib/utils";

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    logger.error(error);
  }, [error]);

  return (
    <div className="container max-w-2xl">
      <EmptyPlaceholder className="mt-4 shadow">
        <EmptyPlaceholder.Icon name="frown" />
        <EmptyPlaceholder.Title>
          <Translation id="common.something-went-wrong">
            Något gick fel
          </Translation>
        </EmptyPlaceholder.Title>
        <EmptyPlaceholder.Description>
          <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-row flex-wrap justify-center gap-2">
              <Link
                href="/"
                className={cn(buttonVariants({ variant: "default" }))}
              >
                <Translation id="common.go-to-home">
                  Gå till startsidan
                </Translation>
              </Link>
              <Button variant="link" onClick={() => window.location.reload()}>
                <Translation id="common.reload">Ladda om sidan</Translation>
              </Button>
            </div>
          </div>
        </EmptyPlaceholder.Description>
      </EmptyPlaceholder>
    </div>
  );
}
